/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addNewLesson, deleteLesson, getSpecificModuleLessons } from "../services/api";
import { TailSpin } from "react-loader-spinner";
import AccessCourse from "components/AccessCourse";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getModules } from "@syncfusion/ej2/spreadsheet";
import TopNav from "components/TopNav";
import PromptsDataTable from "components/PromptsDataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button } from "@mui/material";




const Lesson = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState("");
  const [deleteButtonEnabled, setDeleteButtonEnabled] = useState(false);
  const { moduleId, courseId } = useParams();
  const navigate = useNavigate();
  const [currentModule, setCurrentModule] = useState(moduleId);
  const [moduleName, setModuleName] = useState()

  const [moduleDetails, setModuleDetails] = useState(null)

  const [modules, setModules] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [lessonTitle, setLessonTitle]= useState('')
  const [deleteId, setDeleteId] = useState(null)

  const fetchModules = async (courseId) => {
    try {
      const response = await getModules(courseId);
      if (response) {
        setModules(response?.modules);
      } else {
        toast.error("Something Went Wrong, Please Try Again.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchModules(courseId);
  }, [courseId]);

  const fetchModuleLessons = async (moduleId) => {
    try {
      const moduleLessons = await getSpecificModuleLessons(moduleId);
      setModuleDetails(moduleLessons)
      setModuleName(moduleLessons?.overview?.module_name)
      setLessons(moduleLessons?.lessons);
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchModuleLessons(moduleId);
  }, [moduleId]);

  useEffect(() => {
    setCurrentModule(moduleId);
  }, [moduleId]);

  const handleAddLesson = () => {
    navigate(`/admin/courses/${courseId}/module/${moduleId}/new-lesson`);
  };

  const handleEditLesson = (lesson) => {
    navigate(`/admin/courses/${courseId}/module/${moduleId}/new-lesson`,
      {
        state:{
          ...lesson
        }
      });
  };

  const handleSavePrompts = async (updatedPrompt, editPromptsId, newPrompt) => {
    try {
      if (editPromptsId) {
        // If editQuizId is present, update the existing quiz
        const response = await addNewLesson(moduleId, {
          module_prompt: [updatedPrompt],
        });
        fetchModuleLessons(moduleId);
      } else {
        // If editQuizId is null, add a new quiz
        const response = await addNewLesson(moduleId, {
          module_prompt: [newPrompt],
        });

        fetchModuleLessons(moduleId);
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  const handleCheckboxChange = (lessonId) => {
    const updatedLessonId = selectedLesson === lessonId ? "" : lessonId;

    setSelectedLesson(updatedLessonId);
    setDeleteButtonEnabled(updatedLessonId !== "");
  };


  const handleDeleteLesson = async () => {
    try {
      const deleteddata = await deleteLesson(selectedLesson);

      setLessons((prevLessons) =>
        prevLessons.filter((lesson) => lesson._id !== selectedLesson)
      );

      setSelectedLesson("");
      setDeleteButtonEnabled(false);

      const updatedModuleLessons = await getSpecificModuleLessons(moduleId);
      setLessons(updatedModuleLessons.lessons);
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setDeleteDialogOpen(false);
      const payload = {
        lessons: [
          {
            id: deleteId,
            is_deleted: true
          },
        ],
      };
      await addNewLesson(moduleId, payload);
      fetchModuleLessons(moduleId);
      // Refresh or update lessons here
    } catch (error) {
      console.error('Failed to delete the lesson:', error);
    }
  };

  const { selectedCourse, storedUserId } = AccessCourse({ courseId });
  const CourseTrainerID = selectedCourse?.trainer_id;

  return (
    <>
      <ToastContainer />

      {/* <nav
        class="flex mx-12 my-4 lg:mt-20 md:mt-20 max-sm:mt-20 sm:mt-20"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li class="inline-flex items-center">
            <Link
              to="/admin/courses"
              class="inline-flex items-center text-base font-medium text-[#BDBEBE]"
            >
              <svg
                class="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </Link>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link
                to={`/admin/courses`}
                className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
              >
                Courses
              </Link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link
                to={`/admin/courses/${courseId}`}
                className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
              >
                {selectedCourse?.course_name}
              </Link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link
                to={`/admin/courses/${courseId}/module/${moduleId}`}
                className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
              >
                {moduleName}
              </Link>
            </div>
          </li>
        </ol>
      </nav> */}


      <TopNav
        courseId={courseId}
        selectedCourse={selectedCourse}
        moduleName={moduleName}
        moduleId={moduleId}
      />

      {/* {storedUserId === CourseTrainerID && ( */}
      {/* // <div className="flex justify-end gap-4 mx-12 mt-4"> */}
      {/* <button
          onClick={handleDeleteLesson}
          disabled={!deleteButtonEnabled}
          className={`border rounded py-1.5 px-4 border-[#37383A] font-medium text-sm text-[#BDBEBE] uppercase ${
            !deleteButtonEnabled
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-[#282B2F] hover:bg-[#282B2F]"
          }`}
        >
          Delete Lesson
        </button> */}

      {/* </div> */}
      {/* // )} */}
      {/* {lessons?.length === 0 && (
        <div className="mx-12 mt-4 text-center">
          <p className="text-lg font-semibold text-[#BDBEBE]">
            No lessons for the selected Module
          </p>
        </div>
      )} */}
      <div className="m-5 bg-[#1A1C1E] rounded-lg">
        <div className="flex items-center justify-between header-title">
          <h4>Lessons</h4>
          {storedUserId === CourseTrainerID && (
            <button
              onClick={handleAddLesson}
              className="border rounded py-1.5 px-4 border-[#5b52e7] font-medium text-sm text-[#fff] bg-[#5b52e7] uppercase"
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add New Lesson 
            </button> 
          )}
        </div>

        <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
          {lessons?.length === 0 ? (
            // <div className="mx-12 mt-4 text-center">
            <TailSpin
              color="#FFFFFF"
              height={20}
              width={20}
              style={{ margin: "10px 10px" }}
            />
          ) : // </div>
            lessons ? (
              lessons
                .sort((a, b) => a.lesson_number - b.lesson_number)
                .map((lesson) => (
                  <div
                    key={lesson.id}
                    className="bg-[#242728] border border-[#303234] overflow-hidden rounded-md gap-3"
                  >
                    <Link
                  to={`/admin/courses/${courseId}/module/${moduleId}/lesson/${lesson.id}`}
                  style={{ textDecoration: "none" }}
                >
                    {/* <img
                  src={lesson.image}
                  alt={lesson.name}
                  className="lg:max-w-full lg:w-96 max-sm:max-w-full max-sm:w-full sm:w-full lg:h-40 max-lg:h-40 max-sm:h-[250px] sm:h-[250px] object-cover opacity-100 transition duration-300 ease-in-out hover:opacity-20"
                /> */}
                    {lesson.url ? (
                      lesson.url.includes("youtube.com") ||
                        lesson.url.includes("youtu.be") ? (
                        <div className="">
                          <iframe
                            title="YouTube Video"
                            width="100%"
                            height="170"
                            src={`https://www.youtube.com/embed/${lesson.url.split("v=")[1]?.split("&")[0] ||
                              lesson.url.split("/").pop()
                              }`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      ) : (
                        <div className="">
                          <iframe
                            title="Video"
                            width="100%"
                            height="170"
                            src={lesson.url}
                            frameBorder="0"
                            allow="fullscreen"
                            allowFullScreen
                          ></iframe>
                        </div>
                      )
                    ) : (
                      <div
                        className="loading-spinner"
                        style={{
                          width: "100%",
                          height: "170px",
                          backgroundColor: "#000000",
                          animation: "loading 2s infinite linear",
                        }}
                      >
                        <TailSpin color="#c5c6c7" height={40} width={40} />
                      </div>
                    )}     <div className="flex items-start justify-between">
                      <div>
                        <h5 className="flex align-middle justify-start my-1 text-lg font-normal px-2">{lesson.title}</h5>
                      </div>
                      <div className="flex flex-col">
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={(event) => {
                            // event.stopPropagation();
                            // handleEdit(module, 'interview')
                            handleEditLesson(lesson)
                          }
                          }
                        >
                          <EditIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                        <IconButton
                          color="error"
                          aria-label="delete"
                          onClick={(event) => {
                            setLessonTitle(lesson.title)
                            setDeleteId(lesson.id)
                            setDeleteDialogOpen(true)
                          }
                          }
                        >
                          <DeleteIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      </div>
                    </div>
                    <p className="px-4 opacity-50 my-2.5">
                      {lesson.lesson_description}
                    </p>
                    </Link>
                    {/* <label className="absolute top-1 right-2">
                  <input
                    type="checkbox"
                    checked={lesson?.id === selectedLesson}
                    onChange={() => handleCheckboxChange(lesson?.id)}
                  />
                </label> */}
                  </div>
                ))
            ) : (
              // Render something else if there are no courses
              <div>No Lessons available</div>
            )}
        </div>
      </div>
      <PromptsDataTable
        prompts={moduleDetails?.module_prompt}
        onSavePrompt={handleSavePrompts}
        courseId={courseId}
      />
       <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle
              sx={{
                background: "#242728",
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Confirm Deletion
            </DialogTitle>
            <DialogContent
              sx={{
                background: "#242728",
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <p>Are you sure you want to delete "{lessonTitle}"?</p>
            </DialogContent>
            <DialogActions
              sx={{
                background: "#242728",
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <Button
                onClick={() => setDeleteDialogOpen(false)}
                sx={{
                  mr: 2,
                  mb: 2,
                  color: "#BDBEBE",
                  fontFamily: "Poppins, sans-serif",
                  "&:hover": {
                    background: "#282B2F",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmDelete}
                variant="contained"
                color="error"
                sx={{ mr: 2, mb: 2, fontFamily: "Poppins, sans-serif" }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
    </>
  );
};

export default Lesson;
