import React from 'react';

const ProgressBar = ({ percentage }) => {
    return (
        <div className="flex flex-col items-center w-full ">
            <div className="relative w-full max-w-sm h-3 bg-indigo-400 rounded-full overflow-hidden">
                <div className="h-full bg-indigo-600 rounded-full transition-all duration-300" style={{ width: `${percentage}%` }}></div>
                <span className="absolute right-3 top-1/2 -translate-y-1/2 text-white font-semibold text-[13px]">{percentage}%</span>
            </div>
        </div>
    );
};

export default ProgressBar;
