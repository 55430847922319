import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const List = ({ data, handleEdit }) => {
    return (
        <div className="m-5 course-section">
            <TableContainer component={Paper} className="">
                <Table>
                    <TableHead className="border-2 border-[#37383A] bg-[#292B2D]">
                        <TableRow sx={{ border: '2px solid #37383A' }}>
                            <TableCell
                                sx={{
                                    fontSize: '16px',
                                    color: '#BDBEBE',
                                    fontFamily: 'Poppins, sans-serif'
                                }}>
                                No.
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: '16px',
                                    color: '#BDBEBE',
                                    fontFamily: 'Poppins, sans-serif'
                                }}>
                                Mentor Name
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: '16px',
                                    color: '#BDBEBE',
                                    fontFamily: 'Poppins, sans-serif'
                                }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="border-2 border-[#37383A] bg-[#1A1C1E]">
                        {data?.map((ele, index) => (
                            <TableRow key={ele?.id} sx={{ border: '2px solid #37383A' }}>
                                <TableCell
                                    sx={{
                                        color: '#BDBEBE',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '16px'
                                    }}>
                                    {index + 1}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: '#BDBEBE',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '16px'
                                    }}>
                                    {ele?.name}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: '#BDBEBE',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '16px'
                                    }}
                                    className="action-btn">
                                    <IconButton color="primary" aria-label="edit" onClick={() => handleEdit({ id: ele.id, name: ele.name })}>
                                        <EditIcon />
                                    </IconButton>
                                    {/* <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() =>
                        handleDelete(course.id, course.course_name)
                      }
                    >
                      <DeleteIcon />
                    </IconButton> */}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default List;
