import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/authProvider";
import { useAuthInfo } from "@propelauth/react";
import { setUserId, users } from "services/api";

const Auth = ({ allowedRoles }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authInfo = useAuthInfo();

  const { auth } = useContext(AuthContext);
  const location = useLocation();


  const isAuthorizedRole = useMemo(
    () => allowedRoles.includes(auth.role),
    [allowedRoles, auth]
  );


  useEffect(() => {
      const setUser = async() =>{
        const { role, user:{email , userId}} = auth
        if(role && email && userId){
      const data = {
        overview:{
          email,
          prople_auth_id: userId,
          role
        }
      }
      const response = await users(data)
      if (response?.user_id)
        setUserId(response.user_id)
      }
    }
      setUser()
  }, [isAuthorizedRole]);

  if (!authInfo.userClass) {
    
    return null;
  }

  return (
    <>
      {isAuthorizedRole ? (
        <Outlet />
      ) : (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      )}
    </>
  );
};

export default Auth;
